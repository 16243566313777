<script setup lang="ts">
interface Props {
  size?: 'sm' | 'lg'
  effectEnabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
  default: true,
})

// Define the number of squares and the interval for color change
const numSquares = 500
const squareChangeInterval = 5000
const highlightedSquares = 30

// Reactive variable to track which squares to change
const squaresToChange = ref<number[]>([])

// Function to generate a random number between min and max
const getRandomInt = (min: number, max: number) => {
  if (!props.effectEnabled) return
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// Function to update the colors of selected squares
const updateColors = () => {
  if (!props.effectEnabled) return
  const newSquaresToChange = ref<number[]>([])
  while (newSquaresToChange.value.length < highlightedSquares) {
    const randomIndex = getRandomInt(0, numSquares - 1)
    if (randomIndex && !newSquaresToChange.value.includes(randomIndex)) {
      newSquaresToChange.value.push(randomIndex)
    }
  }

  squaresToChange.value = newSquaresToChange.value
}

// Interval to periodically change colors
let squareChangeIntervalId: any

onMounted(() => {
  if (!props.effectEnabled) return
  updateColors()

  // Set up the interval to change colors
  squareChangeIntervalId = setInterval(() => {
    updateColors()
  }, squareChangeInterval)
})

onUnmounted(() => {
  // Clear the interval when the component is unmounted
  clearInterval(squareChangeIntervalId)
})
</script>

<template>
  <div
    class="after:to-gray-850 absolute inset-0 z-[-1] flex h-[260px] w-full flex-nowrap overflow-hidden after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:h-20 after:bg-gradient-to-b after:from-transparent after:content-['']"
  >
    <div
      class="flex max-h-[330px] w-full flex-wrap overflow-hidden transition-all duration-200"
    >
      <div
        v-for="(square, index) in numSquares"
        :key="index"
        :class="[
          '-mr-px -mt-px flex aspect-square w-[2.5%] max-w-[50px] flex-wrap border transition-all duration-300 hover:z-[0] hover:border-[#D8FC5912] hover:bg-[#D8FC5905] hover:shadow-[0_0_4px_1px_#d8fc5904,0_0_6px_2px_#d8fc5906]',
          squaresToChange.includes(index)
            ? 'border-[#D8FC5906] bg-[#D8FC5904]'
            : 'border-[#D8FC5903] bg-[#D8FC5901]',
        ]"
      ></div>
    </div>
  </div>
</template>
